/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-mdc-snack-bar-container.error-snack {
  --mdc-snackbar-supporting-text-color: #f1f1f1;
  --mdc-snackbar-container-color: rgb(141, 7, 7);
  --mat-mdc-snack-bar-button-color: #bdbdbd;
}
.mat-mdc-snack-bar-container.success-snack {
  --mdc-snackbar-supporting-text-color: #f1f1f1;
  --mdc-snackbar-container-color: rgb(10, 165, 10);
  --mat-mdc-snack-bar-button-color: #bdbdbd;
}
.mat-mdc-snack-bar-container.alert-snack {
  --mdc-snackbar-supporting-text-color: #f1f1f1;
  --mdc-snackbar-container-color:#004171;
  --mat-mdc-snack-bar-button-color: #ffffff;
}

.mat-mdc-form-field-icon-suffix {
  z-index: 1;
}

.status-badge {
  @apply text-xs;
  @apply px-2;
  @apply py-1;
  @apply rounded;
}

.ACTIVE {
  color: white;
  background: green;
}

.DELETED,
.LOCKED {
  color: white;
  background: red;
}

.PENDING {
  color: white;
  background: grey;
}